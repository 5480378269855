import {API_SETTINGS} from 'consts/api'
import {Rest} from 'utils'

export class Settings {
  constructor() {
    this._rest = new Rest()
  }

  async get(name, defval) {
    try {
      const result = await this._rest.get(`${API_SETTINGS}/${name}`)
      return result.value
    }
    catch(err) {
      if (err.http_code === 404) return defval
      throw err
    }
  }

  async put(name, value) {
    // TODO: check response object
    return await this._rest.put(API_SETTINGS, [{key: name, value: value}])
  }

  async remove(name) {
    return await this._rest.delete(API_SETTINGS, [name])
  }
}

const settings = new Settings()
export default settings