/* eslint-disable no-unused-vars */
import {
  BOLD_DIF_LOW,
  BOLD_DIF_UP,
  BOLD_ITALIC_DIF_LOW,
  BOLD_ITALIC_DIF_UP,
  BOLD_ZERO,
  DEF_DIF_LOW,
  DEF_DIF_UP,
  DEF_ZERO,
  ITALIC_DIF_LOW,
  ITALIC_DIF_UP,
  ZERO,
} from 'consts/unicode'


import * as $utils from 'utils';
function getNum(dec_unicode) {
  return [ZERO, DEF_ZERO, BOLD_ZERO].reduce((prev, cur) => {
    const x = dec_unicode - cur
    return x >= 0 && x <= 9 ? x : prev
  }, dec_unicode)
}

function replaceText(p1, upper, lower, nums) {
  let text = p1
    .replace(/[A-Z]/g, char => String.fromCodePoint(char.codePointAt() + upper))
    .replace(/[a-z]/g, char => String.fromCodePoint(char.codePointAt() + lower))

  if (nums) {
    text = text.replace(/\b\d+\b/g, char => char.split('')
      .map((str_num) => String.fromCodePoint(nums + getNum(+str_num.codePointAt())))
      .join('')
    )
  }
  return text
}

function replaceChars(p1, upper, lower, nums) {
  const regex = /<[^>]*>/g // Match HTML tags

  let result = ''
  let lastIndex = 0

  p1.replace(regex, (match, index) => {
    // Replace the text content between tags
    result += replaceText(p1.substring(lastIndex, index), upper, lower, nums)
    // Append the matched tag
    result += match
    lastIndex = index + match.length
    return match
  })

  // Replace the remaining text content after the last tag
  result += replaceText(p1.substring(lastIndex), upper, lower, nums)

  return result
}

function convertBySelector(selector, node, upper, lower, nums) {
  const elems = Array.from(node.querySelectorAll(selector))
  elems.forEach(el => {
    el.innerHTML = replaceChars($utils.str.decodeHTMLEntities(el.innerHTML), upper, lower, nums)
  })
  return node.innerHTML
}

function convertOrderedList(node, intend = '') {
  let elems = Array.from(node.querySelectorAll('ol'))

  elems = elems.filter(x => !elems.some(y => x !== y && y.contains(x)))

  elems.forEach(ol => {
    const childs = ol.querySelectorAll('&>li')
    if (childs?.length) {
      childs.forEach((li, i) => {
        li.before(`\n\n${intend}${i + 1}.`)
      })
      convertOrderedList(ol, intend + '\t')
    }

  })
}


function convertUnorderedList(node, indent = '') {
  let elems = Array.from(node.querySelectorAll('ul'))

  elems = elems.filter(x => !elems.some(y => x !== y && y.contains(x)))
  elems.forEach(ul => {
    const childs = ul.querySelectorAll('&>li')
    if (childs?.length) {
      childs.forEach(li => {
        li.before(`\n\n${indent}${String.fromCodePoint(8226)} `)
      })
      convertUnorderedList(ul, indent + '\t')
    }
  })
}

function replaceAllBr(node) {
  node.querySelectorAll('br').forEach(br => br.replaceWith('\n'))
}

export const convertHtmlToUnicode = (html, cb) => {
  const node = document.createElement('div')
  node.innerHTML = html

  if (typeof cb === 'function') cb(node)
  if (node.querySelector('b') || node.querySelector('i')) {
    // the order is important
    convertBySelector('i b', node, BOLD_ITALIC_DIF_UP, BOLD_ITALIC_DIF_LOW, DEF_ZERO)
    convertBySelector('b i', node, BOLD_ITALIC_DIF_UP, BOLD_ITALIC_DIF_LOW, DEF_ZERO)
    convertBySelector('b', node, BOLD_DIF_UP, BOLD_DIF_LOW, BOLD_ZERO)
    convertBySelector('i', node, ITALIC_DIF_UP, ITALIC_DIF_LOW, DEF_ZERO)
    convertBySelector('*:not(br):not(i):not(b)', node, DEF_DIF_UP, DEF_DIF_LOW, DEF_ZERO)
  }
  convertOrderedList(node)
  convertUnorderedList(node)
  replaceAllBr(node)
  return node.innerText
}
