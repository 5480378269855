
export const CARDS_MOCKS = [
  {
    type: 'persona',
    info: {
      name: 'Mike Sievert',
      position: 'President and Chief Executive Officer (CEO)',
      company: 'T-Mobile US, Inc',
      photo: 'assets/guy.png',
      bio: 'Mike Sievert\'s role as CEO of T-Mobile and his positions on various boards highlight his influence in the technology and telecommunications sectors, underscoring his commitment to innovation and customer service',
      email: '8NjKu@example.com',
      phone: '+1 (555) 555-5555',
      linkedin_url: 'https://www.linkedin.com/in/mikesievert/',
      shared_contact_id: undefined
    },
    sections: [
      {
        title: 'Recent News',
        type: 'links',
        content: [
          {
            date: '2024-09-25T17:32:38.779000Z',
            preview: {
              title: 'Mike Sievert, CEO, T-Mobile – 2024 Wharton MBA Graduation Speech',
              summary: 'Watch Mike Sievert (W\'91), CEO of @T-Mobile, deliver his graduation speech at the 2024 Wharton MBA Graduation ceremony. The event took place on May 19, 2024 at the Palestra on the campus of the University of Pennsylvania.',
              preview_url: 'assets/guy.png',
            },
            source: {
              name: 'Linkedin',
              url: 'https://www.linkedin.com/feed/update/urn:li:activity:7244134891435499521/',
              icon_url: 'assets/icons/social/linkedin.svg'
            }
          },
          {
            date: '2024-09-25T17:32:38.779000Z',
            preview: {
              title: 'Mike Sievert, CEO, T-Mobile – 2024 Wharton MBA Graduation Speech',
              summary: 'Watch Mike Sievert (W\'91), CEO of @T-Mobile, deliver his graduation speech at the 2024 Wharton MBA Graduation ceremony. The event took place on May 19, 2024 at the Palestra on the campus of the University of Pennsylvania.',
              preview_url: 'assets/guy.png',
            },
            source: {
              name: 'Linkedin',
              url: 'https://www.linkedin.com/feed/update/urn:li:activity:7244134891435499521/',
              icon_url: 'assets/icons/social/linkedin.svg'
            }
          },
        ],
      },
      {
        title: 'Career & Education',
        type: 'career',
        jobs: [
          {
            logo_url: 'assets/ATT.png',
            position: 'President and CEO',
            company: 'T-Mobile US',
            start: '2022-05-04',
            end: 'present'
          },
          {
            logo_url: 'assets/ATT.png',
            position: 'President and CEO',
            company: 'Google',
            start: '2021-04-04',
            end: '2022-05-04',
          },
          {
            logo_url: 'assets/ATT.png',
            position: 'President and CEO',
            company: 'T-Mobile US',
            start: '2020-04-04',
            end: '2021-04-04',
          },
        ],
        education: [
          {
            degree: 'Bachelor\'s degree in Economics',
            institution: 'Wharton School of the University of Pennsylvania',
            year: '1991'
          },
        ],
      },
      {
        title: 'Personality & Interaction Style',
        type: 'markdown',
        content: `
  <u>Summary</u> 

  Mike is known for his data-driven approach and direct communication style. He values detailed, actionable information and enjoys collaborating with innovative teams.

  <u>Key Traits</u> 
  
  Innovator, Strategic Thinker, Listener

  <u>Do's</u>
  - Provide clear, data-backed insights, especially related to business outcomes
  - Focus on forward-looking, innovative technology solutions

  <u>Dont's</u>
  - Avoid vague timelines or ambiguous projections.
  `
      },
      {
        title: 'Objectives & Pain Points',
        type: 'markdown',
        content: `
  **Job Objectives**
  - Continue expanding T-Mobile's 5G network, especially in rural areas  
  - Increase market share against competitors
  
  **Challenges**
  - Navigating regulatory complexities
  - Managing competition with Verizon and AT&T
  
  **Success Metrics**
  - Subscriber growth
  - Market share expansion
  - 5G network performance and coverage
        `
      }

    ],
  },
  {
    type: 'company',
    info: {
      name: 'T-Mobile US, Inc. ',
      place: 'Bellevue, Washington, U.S.',
      category: 'Telecommunications',
      photo: 'assets/t-mobile.png',
      bio: 'T-Mobile US is a major player in the telecom sector, known for its leadership in 5G innovation and mobile services.',
      linkedin_url: 'https://www.linkedin.com/company/t-mobile-us/',
      shared_company_id: undefined
    },
    sections: [
      {
        title: 'Recent News',
        type: 'links',
        content: [
          {
            date: '2024-09-25T17:32:38.779000Z',
            preview: {
              title: 'Mike Sievert, CEO, T-Mobile – 2024 Wharton MBA Graduation Speech',
              summary: 'Watch Mike Sievert (W\'91), CEO of @T-Mobile, deliver his graduation speech at the 2024 Wharton MBA Graduation ceremony. The event took place on May 19, 2024 at the Palestra on the campus of the University of Pennsylvania.',
              preview_url: 'assets/guy.png',
            },
            source: {
              name: 'Linkedin',
              url: 'https://www.linkedin.com/feed/update/urn:li:activity:7244134891435499521/',
              icon_url: 'assets/icons/social/linkedin.svg'
            }
          },
          {
            date: '2024-09-25T17:32:38.779000Z',
            preview: {
              title: 'Mike Sievert, CEO, T-Mobile – 2024 Wharton MBA Graduation Speech',
              summary: 'Watch Mike Sievert (W\'91), CEO of @T-Mobile, deliver his graduation speech at the 2024 Wharton MBA Graduation ceremony. The event took place on May 19, 2024 at the Palestra on the campus of the University of Pennsylvania.',
              preview_url: 'assets/guy.png',
            },
            source: {
              name: 'Linkedin',
              url: 'https://www.linkedin.com/feed/update/urn:li:activity:7244134891435499521/',
              icon_url: 'assets/icons/social/linkedin.svg'
            }
          },
        ],
      },
      {
        title: 'Industry',
        type: 'markdown',
        content: `
  <u>Summary</u> 

  Mike is known for his data-driven approach and direct communication style. He values detailed, actionable information and enjoys collaborating with innovative teams.

  <u>Key Traits</u> 
  
  Innovator, Strategic Thinker, Listener

  <u>Do's</u>
  - Provide clear, data-backed insights, especially related to business outcomes
  - Focus on forward-looking, innovative technology solutions

  <u>Dont's</u>
  - Avoid vague timelines or ambiguous projections.
  `
      },
      {
        title: 'Financial Insights',
        type: 'overview',
        content: {
          metrics: [
            {
              title: 'Revenue',
              value: '$100M',
              growth: '5.5%',
            },
            {
              title: 'Market Cap',
              value: '$100M',
              growth: '5.5%',
            },
            {
              title: 'Earnings Per Share (EPS)',
              value: '$100M',
              growth: '5.5%',
            },
            {
              title: 'Revenue Growth',
              value: '$100M',
              growth: '5.5%',
            },
            {
              title: 'EBITDA Margin',
              value: '$100M',
              growth: '5.5%',
            }
          ],
          insights: `        
  **Industry Overview**
  - Average Revenue Per User (ARPU): $48.62  
  - Churn Rate: 0.88%
  - Total Customers: 113.6 million
  
  **Sales Oportunities**
  - Average Revenue Per User (ARPU): $48.62
  - Churn Rate: 0.88%
  - Total Customers: 113.6 million
  `
        }
      },
    ],
  },
  {
    type: 'meeting',
    info: {
      about: 'Meeting Mike Sievert the CEO of T-Mobile US since 2020. The main objective is to demonstrate 5G network enhancements',
    },
    sections: [
      {
        title: 'Meeting Objectives',
        type: 'markdown',
        content: `
**Meeting Objectives**

Meeting Mike Sievert the CEO of T-Mobile US since 2020. The main objective is to demonstrate 5G network enhancements

**Meeting Success**

Meeting Mike Sievert the CEO of T-Mobile US since 2020. The main objective is to demonstrate 5G network enhancements

**Meeting Failure**
- Lack of customer feedback
- Meeting Mike Sievert the CEO of T-Mobile US since 2020. The main objective is to demonstrate 5G network enhancements
- Meeting Mike Sievert the CEO of T-Mobile US since 2020. The main objective is to demonstrate 5G network enhancements

`,
      },
      {
        title: 'Meeting Management',
        type: 'markdown',
        content: `
**Meeting Management**

Mike prefers a structured, data-driven conversation. Be prepared with visuals and metrics to show Amdocs' value proposition

**Key Points**
- How Amdocs can help optimize T-Mobile’s 5G network expansion and improve customer experiences through advanced operational solutions Amdocs solutions to support growth strategy in 5G and operational efficiency
- How Amdocs can help optimize T-Mobile’s 5G network expansion and improve customer experiences through advanced operational solutions Amdocs solutions to support growth strategy in 5G and operational efficiency

**Meeting Style**

Mike prefers a structured, data-driven conversation. Be prepared with visuals and metrics to show Amdocs' value proposition
`
      },

      {

        title: 'Amdocs Value Proposition for T-Mobile',
        type: 'markdown',
        content: `
**Meeting Objectives**  

Meeting Mike Sievert the CEO of T-Mobile US since 2020. The main objective is to demonstrate 5G network enhancements

**Meeting Success**

Meeting Mike Sievert the CEO of T-Mobile US since 2020. The main objective is to demonstrate 5G network enhancements

**Meeting Failure**
- Lack of customer feedback
- Meeting Mike Sievert the CEO of T-Mobile US since 2020. The main objective is to demonstrate 5G network enhancements
- Meeting Mike Sievert the CEO of T-Mobile US since 2020. The main objective is to demonstrate 5G network enhancements

`,
      }
    ]
  }
]