export function isArray(a) {
  return Array.isArray(a)
}

export function same(a, b) {
  if (!isArray(a) || !isArray(b)) throw new Error(`Arrays expected, got ${typeof a} and ${typeof b}`)
  return a.length === b.length && a.every(el => {
    return b.includes(el)
  })
}

export function* toChunks(arr, n = 4) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n)
  }
}