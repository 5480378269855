/* unplugin-vue-components disabled */
import Modal from './modal'

export default {
  components: {
    Modal,
  },
  props: {
    error: {
      default: undefined,
      type: [String, Object]
    },
    title: {
      type: String,
      default: 'Error occurred'
    },
  },
  emits: ['copy'],
  computed: {
    errtext() {
      return 'Something went wrong. Please copy error message and contact support.'
    },
    errfull() {
      return typeof this.error === 'string' ? this.error : this.error.stack
    }
  },

  mounted () {
    console.log('error')
    this.$refs.modal.open()
  },

  methods: {
    async onCopyError() {
      await $utils.copyText(this.errfull)
    },
    onRestart() {
      this.close()
    },
    close () {
      this.$refs.modal.close()
      this.$store.clearError()
    }
  }
}
