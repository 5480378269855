import {Marked} from 'marked'
import TurndownService from 'turndown'


const to_html_rules = {
  breaks: true, 
  gfm: true,
  renderer: {
    link(href, title, text) {
      return href.startsWith('file://') 
        ? `<a href="${href}" spellcheck="false" class="file">${text}</a>`
        : `<a href="${href}" spellcheck="false" target="_blank">${text}</a>`
    },
    strong(text) {
      return `<b>${text}</b>`
    },
    em(text) {
      return `<i>${text}</i>`
    }
  },
  extensions: [
    {
      name: 'sup',
      level: 'inline',
      start(src) {
        return src.match(/\^/)?.index
      },
      tokenizer(src) {
        // Regex to match ^Number^
        const rule = /^\^([^\\^]+)\^/ 
        const match = rule.exec(src)
        if (match) {
          return {
            type: 'sup', 
            raw: match[0],
            text: match[1].trim() 
          }
        }
        return false
      },
      renderer(token) {
        return `<sup>${token.text}</sup>`
      }
    }
  ],
}


const to_md_rules = [
  {
    name: 'sup',
    filter: 'sup',
    replacement: function (content) {
      return '^' + content + '^'
    }
  }
]

export default class Markdown {
  constructor() {
    this.marked = new Marked()
    this.marked.use(to_html_rules)

    this.turndown = new TurndownService()
    to_md_rules.forEach(rule => this.turndown.addRule(rule.name, rule))
  }
  makeHTML(text) {
    // Fix sources with exclamation sign to be not converted to image
    if (typeof text === 'string') {
      const fixedText = text.replaceAll('![', '\\![')
      return this.marked.parse(fixedText)
    }
    console.log('WARNING: text passed to makeHTML is not a string')
    return ''
  }
  makeMD(text) {
    return this.turndown.turndown(text)
  }
}
