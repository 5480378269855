import {titleCase} from 'title-case'

export function capitalize(str) {
  return titleCase(str)
}

export function join(array, options = {}) {
  if (!Array.isArray(array) || array.length === 0) {
    return 'No items'
  }

  let items = [...array]
  
  if (options.uppercase) {
    items = items.map(item => item.toUpperCase())
  }

  if (options.capitalize) {
    items = items.map(item => capitalize(item))
  }

  if (items.length === 1) {
    return items[0]
  }

  const last = items.pop() 
  const joined = items.join(', ')

  return `${joined} or ${last}`
}

export function trim_to(value, len) {
  if (!len || !value || value.length <= len) {
    return value
  }
  return `${value.substr(0, len-3)}...`
}

export function nthIndexOf(str, search, n) {
  let index = str.indexOf(search)
  while (n-- > 0 && index !== -1) {
    index = str.indexOf(search, index + 1)
  }
  return index
}

export function decodeHTMLEntities(html) {
  const textarea = document.createElement('textarea')
  textarea.innerHTML = html
  return textarea.value.replaceAll('\n', '')
}