export function isObject(obj) {
  return typeof obj === 'object' && obj !== null
}

export function same(a, b) {
  if (!isObject(a) || !isObject(b)) throw new Error(`Objects expected, got ${typeof a} and ${typeof b}`)
  return JSON.stringify(a) === JSON.stringify(b)
}

export function proxy_assign(to, from) {
  // TODO: check if we really need this
  //       may be Object.assign would go through proxy as well
  for (const key in from) {
    to[key] = from[key]
  }
}

export function ensure_keys(object, keys) {
  for (const key of keys) {
    if (key in object) continue
    throw new Error(`Missing key ${key} in object ${JSON.stringify(object)}`)
  }
}
