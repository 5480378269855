import BaseStore from './base-store'
import StoreProxy from './store_proxy'
import $images, {is_image} from './images.js'
import {Rest} from 'utils'
import {API_FILES} from 'consts/api'
import {FTYPES} from 'consts/ftypes'


import * as $utils from 'utils';
export class FileUpload extends BaseStore {
  constructor(file, params, resolve) {
    super({
      id: crypto.randomUUID(),
      file: file,
      params: params,
      resolve: resolve,
      error: undefined,
      result: undefined,
      bytes_loaded: 0,
      aborted: false,
    })
  }

  abort() {
    if (this._state.xhr) {
      this._state.xhr.abort()
    }
  }

  get active() {
    if (this._state.error || this._state.result || this._state.aborted) {
      return false
    }
    return true
  }

  async start() {
    this.abort()

    this._state.error = undefined
    this._state.result = undefined
    this._state.bytes_loaded = 0
    this._state.aborted = false
    this._state.xhr = new XMLHttpRequest()

    const xhr = this._state.xhr
    xhr.open('PUT', API_FILES, true)

    const auth = await this._rest.auth_headers()
    for (const key in auth) {
      xhr.setRequestHeader(key, auth[key])
    }

    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    xhr.setRequestHeader('X-Filename', encodeURIComponent(this._state.file.name)) // headers allow only ISO-8859-1 chars
    xhr.setRequestHeader('X-File-Type', this._state.params.type)
    xhr.setRequestHeader('Content-Type', this._state.file.type)

    if (this._state.params.tags) {
      xhr.setRequestHeader('X-File-Tags', this._state.params.tags)
    }

    xhr.upload.addEventListener('abort', () => {
      this._state.aborted = true
    })

    xhr.upload.addEventListener('error', () => {
      this._state.error = new Error(`Error uploading file. Status: ${xhr.status}, response: ${xhr.responseText}'`)
    })

    xhr.upload.addEventListener('timeout', () => {
      this._state.error = new Error('File upload timed out')
    })

    xhr.upload.addEventListener('progress', (ev) => {
      this._state.bytes_loaded = ev.loaded
    })

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            this._state.result = JSON.parse(xhr.responseText)
            if (this._state.resolve) this.resolve(this._state.result, this)
          }
          catch (err) {
            this._state.error = err
          }
        }
        else {
          if (xhr.status === 403) {
            try {
              let errorText = JSON.parse(xhr.responseText).detail
              FileUpload.showError(`Failed to upload '${this._state.file.name}'. ${errorText}.`)
            }
            catch(err) {
              FileUpload.showError(`Failed to upload ${this._state.file.name}`)
            }
          }
          this._state.error = new Error(`Error uploading file. Status: ${xhr.status}, response: ${xhr.responseText}'`)
        }
      }
    }

    xhr.send(this._state.file)
  }

  static showError(message) {
    $utils.toasts.error(message)
  }

  static checkAllowed(file, type) {
    const info = FTYPES[type]

    if (!info) {
      FileUpload.showError(`Cannot 'upload ${file.name}'. Unknown file type.`)
      return false
    }

    if (file.size > info.max_size) {
      const pretty_bytes = $utils.format.bytes(info.max_size)
      FileUpload.showError(`Cannot 'upload ${file.name}'. File size limit of ${pretty_bytes} exceeded.`)
      return false
    }

    return true
  }
}

export class File extends BaseStore {
  constructor(file) {
    // TODO: GAI-211 sort tags alphabetically, also in doLoad
    //       we do not do it now cause this can take a lot of
    //       time when we have a lot of files until real
    //       pagination is implemented
    super(
      {
        id: undefined,
        user_id: undefined,
        s3_key: undefined,
        name: undefined,
        ext: undefined,
        tags: [],
        type: undefined,
        size: undefined,
        status: undefined,
        weaviate_id: undefined,
        created_at: undefined,
        deleting: false,
        ...file
      },
      {
        schema_version: 1
      }
    )

    if (this.is_image) {
      $images.track(this._state)
    }
  }

  get is_image() {
    return is_image(this._state.type)
  }

  get failed() {
    return this._state.status === 'failed-processing'
  }

  get processing() {
    if (this._state.type === 'document' || this._state.type === 'linkedin-contacts') {
      return this._state.status === 'uploaded' || this._state.status == 'processing'
    }
    return false
  }

  async doLoad(rest, params) {
    const fdata = await rest.get(`${API_FILES}/${this.id}`)
    $utils.object.proxy_assign(this, fdata)

    if (this.is_image) {
      $images.track(this._state)
    }
    else if (this._state.type === 'linkedin-contacts') {
      if (this.failed)
        FileUpload.showError('Failed to import LinkedIn contacts')
    }
  }
}

class Files extends BaseStore {
  // File store can 'view' files by type & tag
  constructor(type, tag = undefined) {
    super({
      files: [],
      uploads: [],
      watchers: [],
      filtered: false,
      total: 0
    })

    this.type = type
    this.tag = tag
    this.watch = new $utils.timers.Later(5000)
  }

  get view() {
    const result = {type: this.type}
    if (this.tag) result['tags'] = [this.tag]
    return result
  }

  async doAbort() {
    this._state.files.forEach(file => file.abortAll())
  }

  _watch_processing() {
    this.watch.repeat(() => {
      this._state.files.forEach(file => {if (file.processing) file.load()})
      return this._state.files.find(file => file.processing) != undefined
    })
  }

  async _loadTotal(rest) {
    const {total} = await rest.get(`${API_FILES}/stats`, this.view)
    this._state.total = total
  }

  async doLoad(rest, params) {
    await this._loadTotal(rest)

    // TODO: GAI-211 implement real pagination
    this._state.filtered = Object.keys(params).length > 0
    // TODO: check, if params have save keys as store view then combine, not override
    const files = await rest.get(API_FILES, {...params, ...this.view, limit: 500})

    if (!files.length) {
      this._state.files = []
      return
    }

    this._state.files = []
    files.forEach(fdata => this._state.files.push(new File(fdata)))
    this._watch_processing()
  }

  async doSave(rest, changed) {
  }

  deleteUpload(id) {
    const ind = this._state.uploads.findIndex(el => el.id === id)
    this._state.uploads[ind].abort()
    this._state.uploads.splice(ind, 1)
  }

  upload(file, {resolve} = {}) {
    if (!FileUpload.checkAllowed(file, this.view.type)) {
      return
    }

    const upload = new FileUpload(file, this.view, async (result, upload) => {
      // if we already have file with the same id (reupload) then replace
      // otherwise just add new file
      const curr_idx = this._state.files.findIndex(file => file.id === result.id)
      if (curr_idx !== -1) {
        this._state.files[curr_idx] = new File(result)
      }
      else {
        this._state.files.push(new File(result))
        await this._loadTotal(this._rest)
      }

      // and watch for processing
      this._watch_processing()

      if (is_image(this.type)) $images.track(result)
      if (resolve) resolve(result, upload)
    })

    this._state.uploads.push(upload)
    upload.start()
  }

  async delete(file_id) {
    let idx = this._state.files.findIndex(file => file.id === file_id)
    this._state.files[idx].deleting = true

    await this._rest.delete(`${API_FILES}/${file_id}`)
    const file = this.remove(file_id)

    if (file.is_image) {
      $images.untrack(file_id)
    }
  }

  remove(file_id) {
    const idx = this._state.files.findIndex(file => file.id === file_id)
    return this._state.files.splice(idx, 1)[0]
  }

  async addSamplePost(inline_text) {   
    //
    // Form file name
    // 
    
    // remove all non-eni codepoints

    let text = inline_text.replace(/[^\x20-\x7F]/g, '').trim()
    if (text.length === 0) text = 'Sample post'
  
    // Get first 3 words
    let idx = $utils.str.nthIndexOf(text, ' ', 3)
    idx = idx == -1 ? Math.min(text.length, 15) : idx
    const name = text.substring(0, idx)
    
    // If same file name already exists add counter and try again
    const findFile = (check) => {
      for (const file of this._state.files) {
        if (file.name === check) {
          return true
        }
      }
      return false
    }
  
    let counter = 0
    let fname = `${name}.txt`
    while(findFile(fname)) {
      counter++
      fname = `${name}_${counter}.txt`
    }
  
    //
    // Create and upload file
    //
    const file = new window.File([inline_text], fname, {type: 'text/plain'})
    return new Promise((resolve) => {
      this.upload(file, {
        // do not reload the whole store if it is not necessary
        reload: true,
        resolve: async (result, upload) => {
          this.deleteUpload(upload.id)
          resolve(result)
        }
      })
    })
  }
}

//
// Just get file meta from server
// File object won't belong to any store
//
export async function get_file(file_id) {
  const rest = new Rest()
  const json = await rest.get(`${API_FILES}/${file_id}`)
  return new File(json)
}

export async function downloadFile(file_id) {
  const file = await get_file(file_id)
  await $utils.downloadFile(file.url)
}

//
// This function is left only for the old code
// DO NOT USE IT
//
export async function upload_file(file, {type}) {
  // fetch doesn't allow to track progress
  const rest = new Rest()
  const auth_headers = await rest.auth_headers()
  const resp = await fetch(API_FILES, {
    method: 'PUT',
    headers: {
      ...auth_headers,
      'X-Requested-With': 'XMLHttpRequest',
      'X-Filename': encodeURIComponent(file.name), // headers allow only ISO-8859-1 chars
      'X-File-Type': type
    },
    body: file
  })

  if (resp.status < 200 || resp.status > 299) {
    throw new Error(resp.statusText)
  }

  const json = await resp.json()
  return new File(json)
}

//
// Global stores
//
const stores = {}

export function create_store(type, tag = undefined) {
  return new StoreProxy(new Files(type, tag), {array_prop: 'files'})
}

export function get_store(type, tag = undefined) {
  const key = type + (tag ? `-${tag}` : '')
  if (!stores[key]) stores[key] = create_store(type, tag)
  return stores[key]
}

const documents = get_store('document')
export default documents
