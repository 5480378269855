import {getCurrentUser} from 'aws-amplify/auth'
import {createRouter, createWebHistory} from 'vue-router'
import {SUCCESS_OAUTH} from 'consts/event'
import {lazyLoad} from 'utils'
import $integrations from 'stores/integrations'

const routes = [
  {
    path: '/login',
    component: lazyLoad(() => import('pages/login')),
    name: 'login',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      requiresAuth: true,
    },
    component: lazyLoad(() => import('pages/logout')),
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    meta: {
      requiresAuth: true,
    },
    component: lazyLoad(() => import('pages/onboarding')),
  },

  {
    path: '/oauth',
    name: 'oauth',
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: 'success_login',
        name: 'success_login',
        component: lazyLoad(() => import('pages/oauth/success-login')),
        props: route => ({type: route.query.i_type})
      },
      {
        path: 'error',
        name: 'error',
        component: lazyLoad(() => import('pages/oauth/error')),
        props: route => ({type: route.query.i_type})
      },
      {
        path: 'cancelled_login',
        name: 'cancelled_login'
      },
    ]
  },

  {
    path: '/',
    name: 'root',
    component: lazyLoad(() => import('pages/root-layout')),
    meta: {
      requiresAuth: true,
    },
    redirect: {
      name: 'new-chat'
    },
    children: [
      {
        path: '/old',
        name: 'old',
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'profile',
            component: lazyLoad(() => import('pages/Profile')),
            name: 'profile'
          }
        ]
      },
      {
        path: 'new-chat',
        component: lazyLoad(() => import('pages/chats')),
        name: 'new-chat'
      },
      {
        name: 'chat',
        path: 'chat',
        component: lazyLoad(() => import('pages/chats'))
      },
      {
        path: '/files',
        component: lazyLoad(() => import('pages/files')),
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'settings',
        path: '/settings',
        component: lazyLoad(() => import('pages/settings')),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    redirect: {
      name: 'root'
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/oauth/success_login') {
    if (!window.opener) {
      return next(to.query?.redirect || '/')
    }
    window.opener.dispatchEvent(new CustomEvent(SUCCESS_OAUTH, {detail: {i_type: to.query.i_type}}))
    return next()
  }
  else if (to.path === '/oauth/error') {
    if (!window.opener) {
      return next(to.query?.redirect || '/')
    }
    return next()
  }
  else if (to.path === '/oauth/cancelled_login') {
    window.close()
  }
  else if (to.path === '/insufficient_permissions_login') {
    window.close()
  }
  else if (to.path === '/login') {
    getCurrentUser()
      .then(() => next(to.query?.redirect || '/'))
      .catch(() => next())
  }
  else if (to.matched.some(record => record.meta.requiresAuth)) {
    getCurrentUser()
      .then(() => next())
      .catch(() => next({
        name: 'login',
        query: {redirect: to.path}
      }))
  }
  else {
    return next()
  }
})

if (!window.opener) {
  window.addEventListener(SUCCESS_OAUTH, async (event) => {
    const integration = $integrations.getIntegration(event.detail.i_type)
    await integration.load()
  })
}


export default router