export const INTEGRATIONS = [
  {
    name: 'LinkedIn',
    logo: 'assets/linkedin.svg',
    description: 'Personalized outreach with social updates.',
    connected: false,
    type: 'linkedin',
    store: undefined,
    disabled: false
  },
  {
    name: 'Google Calendar',
    logo: 'assets/google-calendar.svg',
    description: 'Synchronizing events from your calendar.',
    connected: false,
    type: 'google_calendar',
    store: undefined
  },
  {
    name: 'Microsoft Outlook Calendar',
    logo: 'assets/ms-calendar.svg',
    description: 'Synchronizing events from your calendar.',
    connected: false,
    type: 'ms_calendar',
    store: undefined
  },
  // Will be uncommented in the future, do not delete
  // {
  //   name: 'Google Mail',
  //   logo: 'assets/google-mail.svg',
  //   description: 'Synchronizing emails from your Google Mail.',
  //   connected: false,
  //   type: 'google_mail',
  //   store: undefined,
  //   disabled: true
  // },
  // {
  //   name: 'Google Drive',
  //   logo: 'assets/google-drive.svg',
  //   description: 'Synchronizing files from your Google Drive.',
  //   connected: false,
  //   type: 'google_drive',
  //   store: undefined,
  //   disabled: true
  // },
]

export const INTEGRATION_TYPES = INTEGRATIONS.map(integration => integration.type)