import {API_TASKS} from 'consts/api'
import {Cancelled, TaskError} from './errors.js'
import Rest from './rest.js'

export const TASK_STATUS = {
  COMPLETED: 'completed',
  FAILED: 'failed',
  RUNNING: 'running'
}

export default class Tasks {
  constructor() {
    this._rest = new Rest()
    this._tasks = []
  }

  async start(apiUrl, args = {}) {
    const {id} = await this._rest.post(apiUrl, args)
    return id
  }

  async get(task_id, options) {
    return await this._rest.get(`${API_TASKS}/${task_id}`, undefined, options)
  }

  async exists(task_id) {
    const task = await this.get(task_id, {throw_404: false})
    return task !== undefined
  }

  async getArg(task_id) {
    const {task_arg} = await this.get(task_id)
    return JSON.parse(task_arg)
  }

  cancelOne(task_id) {
    const task = this._tasks.find(x => x.task_id === task_id)
    if (task) {
      this._killTask(task)
    }
  }

  cancelAll() {
    for (const task of this._tasks) {
      this._killTask(task)
    }
    this._tasks = []
  }

  _killTask(task) {
    clearTimeout(task.timeout_id)
    task.reject(new Cancelled())
  }

  async wait(task_id, timeout = 1000, inermediate_cback = undefined) {
    const data = await this.get(task_id)

    switch (data.status) {
      case TASK_STATUS.COMPLETED: return data.result
      case TASK_STATUS.FAILED: throw new TaskError(task_id, data.error)
    }

    if (inermediate_cback) {
      inermediate_cback(data)
    }

    return new Promise((resolve, reject) => {
      const timeout_id = setTimeout(() => {
        this._tasks = this._tasks.filter(task => task.timeout_id != timeout_id)
        resolve(this.wait(task_id, timeout, inermediate_cback))
      }, timeout)
      this._tasks.push({reject, timeout_id, task_id})
    })
  }

  async waitTask(task_id, timeout = 1000) {
    const task = await this.get(task_id)

    switch (task.status) {
      case TASK_STATUS.COMPLETED:
      case TASK_STATUS.FAILED:
        return task
    }

    return new Promise((resolve, reject) => {
      const timeout_id = setTimeout(() => {
        this._tasks = this._tasks.filter(task => task.timeout_id != timeout_id)
        resolve(this.waitTask(task_id, timeout))
      }, timeout)
      this._tasks.push({reject, timeout_id})
    })
  }
}
