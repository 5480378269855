import 'fomantic-ui-less/semantic.less'
import 'fomantic-ui/dist/semantic'
import 'vue3-carousel/dist/carousel.css'
import './styles/old-global.styl'
import './styles/global.styl'
import './styles/icons.styl'
import {createApp, nextTick} from 'vue'
import * as Directives from './directives'
import PrimeVue from 'primevue/config'
import App from './app'
import store from 'stores/global'
import router from 'router'
import {VueRangy} from 'plugins'
import {Amplify} from 'aws-amplify'
import AmplifyVue from '@aws-amplify/ui-vue'
import {Rest} from 'utils'
import {API_REALMS} from 'consts/api'


import * as $utils from 'utils';
if (!LOCAL_FRONT) {
  //
  // In non-local mode we force HTTPS
  //
  if (window.location.protocol !== 'https:') {
    location.replace(`https:${location.href.substring(location.protocol.length)}`)
  }
}

//
// Check UI version and cleanup if changed
//
const VERSION_KEY = 'version'
const REALMS_KEY = 'realms'
const VERSION = '0.0.3'

if (localStorage.getItem(VERSION_KEY) !== VERSION) {
  console.log('UI version changed: clearing local storage')
  localStorage.clear()
  localStorage.setItem(VERSION_KEY, VERSION)
}

//
// Configure authentication
// TODO: This can take time. Need to show loading indicator but nothing exist at this moment :(
//       Unsure how to fix this. Amplify must be initialized before router
//
nextTick(async () => {
  const cognito = {
    userPoolId: COGNITO_POOL,
    userPoolClientId: COGNITO_CLIENT,
    passwordFormat: {
      minLength: 12,
      requireLowercase: true,
      requireUppercase: true,
      requireNumbers: true,
      requireSpecialCharacters: true,
    }
  }

  const rest = new Rest({auth: false})
  const realms = await rest.get(API_REALMS)
  console.log(`Auth realms ${realms}`)

  // if realms changed need to clear local amplify state or getUser might never return
  const oldRealms = localStorage.getItem(REALMS_KEY)
  if (oldRealms && oldRealms !== realms.toString()) {
    const to_remove = Object.keys(localStorage).filter(key => key.startsWith('Cognito'))
    to_remove.forEach(key => localStorage.removeItem(key))
  }

  if (realms.length) {
    // idp_initiated=false should go first. First oauth url is used by amplify in SP-initiated flow
    const urls = [`${window.location.origin}/login?idp_initiated=false`]

    for (const realm of realms) {
      urls.push(`${window.location.origin}/login?idp_initiated=true&idp=${encodeURIComponent(realm)}`)
    }

    cognito['loginWith'] = {
      oauth: {
        redirectSignIn: urls,
        redirectSignOut: [`${window.location.origin}/login`],
        responseType: 'code',
        scopes: ['aws.cognito.signin.user.admin', 'openid', 'email', 'profile'],
        domain: 'authorize.dev.talking.buzz',
      }
    }
  }

  localStorage.setItem(REALMS_KEY, realms)
  Amplify.configure({
    Auth: {
      Cognito: cognito
    }
  })

  //
  // Setup Vue
  //
  const vueApp = createApp(App)
  const {VReadMixin, ...directives} = Directives

  vueApp.mixin(VReadMixin)
  Object.keys(directives).forEach(key => vueApp.directive(key, directives[key]))

  vueApp.config.errorHandler = (err, instance, info) => {
    store.setError(err)
  }

  window.addEventListener('unhandledrejection', ev => {
    ev.preventDefault()
    store.setError(ev.reason)
  })

  vueApp.config.globalProperties.$store = store
  vueApp.config.globalProperties.$state = store.state
  vueApp.config.globalProperties.$utils = $utils

  vueApp
    .use(PrimeVue, {unstyled: true})
    .use(VueRangy, {init: true})
    .use(AmplifyVue)
    .use(router)
    .mount('body')
})
