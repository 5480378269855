import {getIntegrations} from 'consts/integrations'
import BaseStore from './base-store'
import StoreProxy from './store_proxy'
import {API_INTEGRATIONS} from 'consts/api'
import {oauth as $oauth} from 'utils'
import {nextTick} from 'vue'


import * as $utils from 'utils';
export class Integration extends BaseStore {
  constructor(values) {
    super(
      {
        id: undefined,
        enabled: false,
        connected: false,
        type: undefined,
        ...values
      }
    )
  }

  async doSave(rest, changed) {
    const to_write = {}
    for (const key of changed) to_write[key] = this._state[key]
    await rest.patch(`${API_INTEGRATIONS}/${this._state.id}`, to_write)
  }

  async doLoad(rest, params) {
    const integration = await rest.get(`${API_INTEGRATIONS}/${this._state.id}`, params)
    Object.assign(this._state, integration)
    integrations.checkReconnect(this._state.type)
  }
}

export class Integrations extends BaseStore {
  constructor() {
    super({
      integrations: []
    })
  }

  async doLoad(rest, params) {
    const items = await rest.get(API_INTEGRATIONS, params)
    this._state.integrations = []
    for (const item of items) {
      this._state.integrations.push(new Integration(item))
    }
  }

  getIntegration(type) {
    for (let item of this._state.integrations) {
      if (item.type === type) {
        return item
      }
    }
    return undefined
  }

  showSuccessToast(type, message) {
    const data = getIntegrations().find(x => x.type === type)
    $utils.toasts.message({
      showImage: data.logo,
      class: 'success',
      message
    })
  }

  showErrorToast(type, message = 'Something went wrong. Please try again later.') {
    const data = getIntegrations().find(x => x.type === type)
    $utils.toasts.message({
      displayTime: 30000,
      showImage: data.logo,
      class: 'integrations error',
      message
    })
  }

  async connect(type) {
    let integration = this.getIntegration(type)
    if (integration === undefined) integration = await this.newIntegration({type})
    await $oauth.authorize(`${API_INTEGRATIONS}/${integration.id}/authorize`, type)
  }

  async newIntegration(params) {
    const data = await this._rest.post(API_INTEGRATIONS, params)
    const new_integration = new Integration(data)
    this._state.integrations.push(new_integration)
    return new_integration
  }

  async delete(type) {
    const integration = this.getIntegration(type)
    await this._rest.delete(`${API_INTEGRATIONS}/${integration.id}`)
    this._state.integrations = this._state.integrations.filter(x => x.id !== integration.id)
  }

  async enable(type, enabled) {
    const integration = this.getIntegration(type)
    integration.enabled = enabled
    nextTick(async () => {
      await integration.save()
    })
  }

  checkReconnect(type) {
    for (let item of this._state.integrations) {
      if (type === undefined || item.type === type) {
        if (item.enabled && !item.connected) {
          this._showReconnectToast(item.type)
        }
      }
    }
  }

  _showReconnectToast(type) {
    const data = getIntegrations().find(x => x.type === type)
    $utils.toasts.message({
      class: 'integrations',
      displayTime: 60000,
      message: `Your <b>${data.name}</b> credentials have expired. Please reconnect or disable your integration.`,
      showImage: data.logo,
      actions: [
        {
          text: 'Disable',
          class: 'tertiary tiny',
          click: () => this.delete(data.type)
        },
        {
          text: 'Reconnect',
          class: 'primary tiny',
          click: () => this.connect(data.type)
        },
      ]
    })
  }
}

const integrations = new StoreProxy(new Integrations(), {array_prop: 'integrations'})
export default integrations
