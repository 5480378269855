import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d9807fa6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal-dialog"
}
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  key: 0,
  class: "controls"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#modals" }, [
    ($data.show)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass('modal-content ui segment ' + ($props.segment || '')),
            style: _normalizeStyle($options.content_style)
          }, [
            _createElementVNode("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "content")
            ]),
            (_ctx.$slots.controls)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  ($props.centered)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                    : _createCommentVNode("v-if", true),
                  _renderSlot(_ctx.$slots, "controls"),
                  ($props.centered)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                    : _createCommentVNode("v-if", true)
                ]))
              : _createCommentVNode("v-if", true)
          ], 6 /* CLASS, STYLE */)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}