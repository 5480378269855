export async function copyText(text) {
  await navigator.clipboard.writeText(text)
}

export function downloadFile(url) {
  const iframe = document.createElement('iframe')
  iframe.setAttribute('sandbox', 'allow-downloads allow-scripts')
  iframe.setAttribute('style', 'display: none')
  iframe.src = url
  document.body.appendChild(iframe)
}