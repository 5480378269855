import {Marked} from 'marked'
import TurndownService from 'turndown'


export default class Markdown {
  constructor(to_html = {}, to_md_rules = []){
    this.marked = new Marked()

    this.marked.use(to_html)
  
    this.turndown = new TurndownService()

    to_md_rules.forEach(rule => this.turndown.addRule(rule.name, rule))
  }
  makeHTML(text) {
    return this.marked.parse(text)
  }
  makeMD(text) {
    return this.turndown.turndown(text)
  }
}
