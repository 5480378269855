

const __default__ = {
  props: {
    max_width: {
      type: String,
      default: '50%'
    },
    min_width: {
      type: String,
      default: '30em'
    },
    segment: {
      type: String,
      default: undefined
    },
    centered: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      show: false,
    }
  },

  computed: {
    content_style() {
      let style = {
        'background-color': '#fff',
      }
      if (this.max_width) {
        style['max-width'] = this.max_width
      }
      if (this.min_width) {
        style['min-width'] = this.min_width
      }
      return style
    },
    ctrls_justify() {
      return this.centered ? 'center' : 'right'
    },
    id() {
      return Math.random()
    }
  },

  methods: {
    close () {
      this.show = false
      document.getElementById('application').style.opacity = 1
    },

    open () {
      this.show = true
      document.getElementById('application').style.opacity = 0.3
    },
  }
}

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "d9807fa6-ctrls_justify": (_ctx.ctrls_justify)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__