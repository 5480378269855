import {reactive, watch} from 'vue'
import {Rest, Tasks} from 'utils'


import * as $utils from 'utils';
export default class BaseStore {
  constructor(state = {}, options = {}) {
    this._options = options
    this._changed = new Set()
    this._rest = new Rest()
    this._tasks = new Tasks()
    this._load_params = {}
    this._state = reactive({
      ...state,
      loading: false,
      loaded: false,
      saving: false,
      saved_at: undefined,
    })
    
    if ('schema_version' in this._state) {
      this.checkSchemaVersion()
    }

    // we do not track changes for 'technical' keys added by the BaseStore
    this._do_not_track = [
      'loaded', 'loading', 'saving', 'saved_at',
      ...options.do_not_track || []
    ]

    //
    // Here we crate getters and setters for props in _state on this.
    // If there is already a getter/setter defined for the same prop on prototype then
    // we redirect call to it. So we can have custom getters and setters
    // for props in the descendants. For example
    // 
    // class Strore: extends BaseStore {
    //   constructor() {
    //     super({
    //       name: 'Max',
    //       age: 22
    //     })
    //   }
    //   get name() {
    //     return 'My name: ' + this._state.name
    //   }
    // }  
    //
    // So there will be 'set name()', 'get age()' and 'set age()' defined for Store
    // instances while original 'get name()' would be preserved.
    //  
    const prototype = Object.getPrototypeOf(this)
    const descriptors = Object.getOwnPropertyDescriptors(prototype)
    for (const key in this._state) {      
      Object.defineProperty(this, key, {
        get: function () {
          if (descriptors[key]?.get) {
            return descriptors[key].get.call(this)
          }
          return this._state[key]
        },
        set: function(value) {
          if (descriptors[key]?.set) {
            descriptors[key].set.call(this, value)
            return
          }
          this._state[key] = value
        }
      })

      //
      // Install watchers to watch for changes in _state
      // it is better cause .set in property above would 
      // not track all the changes. For example when _state
      // is accessed directly by store methods or when
      // there is an array key present that requires
      // a deep watcher
      //
      // Also since we're watching a reactive object we cannot
      // watch it directly and should watch getter on property.
      // More details: https://vuejs.org/guide/essentials/watchers.html#watch-source-types
      // 
      if (!this._do_not_track.includes(key)) {
        watch(
          () => this._state[key], 
          () => this._changed.add(key), 
          {deep: (this._state[key] instanceof Array)}
        )
      }
    }
  }

  async doAbort() {
  }

  async abortAll() {
    this._rest.abortAll()
    this.doAbort()
  }

  async doLoad (rest, params) {
    throw new Error('doLoad not implemented')
  }

  checkSchemaVersion() {
    if (!this._options.schema_version) return
    if (this._state.schema_version !== this._options.schema_version) {
      throw new Error(
        `${this.constructor.name} version v${this._options.schema_version} expected, ` +
        `v${this._state.schema_version} found.`
      )
    }
  }

  async load (params = {}, options = {}) {
    if (this._state.loading && !options.force && $utils.object.same(params, this._load_params)) {
      return
    }
    
    this.abortAll()
    this._state.loading = true
    this._state.loaded = false
    this._load_params = params
    await this.doLoad(this._rest, params)
    this.checkSchemaVersion()
    this._state.loading = false
    this._state.loaded = true
  }

  async doSave (rest, changed) {
    throw new Error('doSave not implemented')
  }

  async save (what) {
    // if what is provided actual save is intersection between what and changed, i.e. don't save
    // something that hasn't changed
    const to_save = what ? new Set([...what].filter(item => this._changed.has(item))) : this._changed
    if (to_save.size === 0) return

    try {
      this._state.saving = true
      // false from doSave means that store 
      // does not want/cannot save at the moment
      const res = await this.doSave(this._rest, to_save) 
      if (res !== undefined && !res) return
        
      if (what && this._changed.size) {
        this._changed = new Set([...this._changed].filter(key => !what.includes(key)))
      }
      else {
        this._changed = new Set()
      }
      this._state.saved_at = Date.now()
    }
    finally {
      this._state.saving = false
    }
  }
}
