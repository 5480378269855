
import ErrorModal from 'controls/error-modal.vue'
import $global from 'stores/global'
import {getCurrentUser} from '@aws-amplify/auth'
import {Hub} from '@aws-amplify/core'
import {Later} from 'utils/timers'

export default {
  components: {
    ErrorModal,
  },
  data() {
    return {
      inactivity_timer: new Later(1000 * 60 * 60),
      inactivity_events: ['mousemove', 'touchstart', 'touchmove', 'keydown', 'click', 'scroll'],
      signed_in: false
    }
  },
  computed: {
    error () {
      return $global.error
    },
    loading () {
      return $global.loading
    }
  }, 
  watch: {
    signed_in: async function(signed_in) {
      if (signed_in) {
        await $global.start()
        this.startInactivityTimer()

        
        if (!$global.onboarding?.completed) {
          $global.goToOnboarding()
          return
        }
        
        if (this.$route.path === '/login') {         
          const go_to = this.$route.query.redirect || '/'
          this.$router.push(go_to)
          return 
        }
      }

      if (!signed_in) {
        this.destroyInactivityTimer() 
        window.location.reload()
      }
    }
  },
  async created () {
    if ($global.parent_page) return
    Hub.listen('auth', async data => {
      switch(data.payload.event) {
        case 'signedIn': this.signed_in = true; break
        case 'signedOut': this.signed_in = false; break
      }
    })
    
    try {
      await getCurrentUser()
      this.signed_in = true
    }
    catch(err) {
      // if there is no current user we simply ignore
      // router would detect this as well and redirect to login
    }
  },
  methods: {
    startInactivityTimer() {
      const resetInactivityTimer = () => this.resetInactivityTimer()
      this.inactivity_events.forEach(event => {
        window.addEventListener(event, resetInactivityTimer)
      })
      this.resetInactivityTimer()
    },

    resetInactivityTimer() {
      this.inactivity_timer.once(
        async () => {
          await $global.logout()
        }
      )
    },

    destroyInactivityTimer() {
      const resetInactivityTimer = () => this.resetInactivityTimer()
      this.inactivity_events.forEach(event => {
        window.removeEventListener(event, resetInactivityTimer)
      })
    },
  }
}
