import {Rest} from 'utils/index'

export async function authorize(url, title) {
  const rest = new Rest()
  const res = await rest.post(url)
  openOauthModal(res.url, title)
}

function openOauthModal(url, title) {
  const width = 700
  const height = 600
  const x = screen.width / 2 - width / 2
  const y = screen.height / 2 - height / 2

  const new_window = window.open(
    url,
    title,
    `height=${height},width=${width},left=${x},top=${y}`
  )
  if (window.focus) {
    new_window?.focus()
  }
}
