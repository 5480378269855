/* eslint-disable no-prototype-builtins */
import * as errors from './errors.js'
import {fetchAuthSession, signOut} from '@aws-amplify/auth'

export default class Rest {
  constructor(options) {
    this.abort_controller = new AbortController()
    this.get = this._request('GET')
    this.put = this._request('PUT')
    this.post = this._request('POST')
    this.patch = this._request('PATCH')
    this.delete = this._request('DELETE')
    this.options = {auth: true, json: true, ...options}
  }

  abortAll() {
    this.abort_controller.abort()
  }

  async auth_headers() {
    try {
      const session = await fetchAuthSession()
      const id_token = session.tokens.idToken.toString()
      return {
        'Authorization': `Bearer ${id_token}`
      }
    } catch (err) {
      await signOut()
    }
  }

  _to_json_nothrow(text) {
    try {
      return JSON.parse(text)
    }
    catch (err) {
      return undefined
    }
  }

  _to_query_string(obj) {
    return (Object.keys(obj))
      .filter(key => obj[key])
      .map(key => key + '=' + obj[key])
      .join('&')
  }

  _request(method) {
    return async (baseUrl, params, config = {}) => {
      config = {throw_404: true, ...config}

      const options = {
        method,
        signal: config.abort,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          ...config.headers
        }
      }

      if (this.options.auth) {
        const auth_headers = await this.auth_headers()
        options.headers = {...options.headers, ...auth_headers}
      }

      const is_filedata = config?.hasOwnProperty('fileData')
      const is_formdata = (
        options.headers['Content-Type'] === 'application/x-www-form-urlencoded' ||
        is_filedata
      )

      if (!is_filedata) {  // for uploading files Content-Type should be set automatically by the browser
        options.headers['Content-Type'] = is_formdata ? 'application/x-www-form-urlencoded' : 'application/json'
      }

      let url = baseUrl
      if (params) {
        switch (method) {
          case 'GET': url += '?' + this._to_query_string(params); break
          default: options.body = is_formdata ? params : JSON.stringify(params)
        }
      }

      try {
        const res = await fetch(url, options)
        const text = await res.text()

        if (!res.ok) {
          const json = this._to_json_nothrow(text)
          const message = json && json.detail ? JSON.stringify(json.detail) : res.statusText

          if (res.status === 401) {
            throw new errors.Unauthorized(url, message, text)
          }

          if (res.status === 404) {
            if (!config.throw_404) return undefined
            throw new errors.NotFound(url, message, text)
          }

          if (res.status === 500 && json.code) {
            throw new errors.CustomError(url, json.code, json.detail)
          }

          throw new errors.RestError(url, res.status, message, text)
        }

        if (res.status == 204) {
          return {}
        }

        try {
          return this.options.json ? JSON.parse(text) : text
        }
        catch (err) {
          // JSON.parse throws Syntax error that includes very little info
          // let's be a bit more informative
          throw new errors.RestParseError(url, res.status, err.message, text)
        }
      }
      catch (err) {
        if (err.name === 'AbortError') throw new errors.Cancelled('Request cancelled by user action')
        throw err
      }
    }
  }
}
