import * as $utils from 'utils';
function deleteEmptyLines(richText){
  return richText.startsWith('{\\pard\\par}') ? deleteEmptyLines(richText.substring(11)) : richText
}


function handleUl(ul, level = 300){
  const nested_ui = ul.querySelectorAll(':scope > li > ul')
  nested_ui.forEach(nested => {
    handleUl(nested, level + 300)
  })
  let rich_html = ul.innerHTML
  if (nested_ui.length) {
    rich_html = rich_html.replace(/<ul(?:\s+[^>]*)?>|<ol(?:\s+[^>]*)?>/ig, '{\\pard\\par}\n')
    rich_html = rich_html.replace(/<\/ul>|<\/ol>/ig, '')
  }
  
  rich_html = rich_html.replace(/<li(?:\s+[^>]*)?>/ig, `{\\pard\\fi-300\\li${level}\\bullet\\tab\n`)
  rich_html = rich_html.replace(/<\/li>/ig, '\n\\par}')
  ul.innerHTML = rich_html
}

function bulletListsToRtf(html) {
  const doc = document.createElement('div')
  doc.innerHTML = html

  const top_lvl_uls = [...doc.querySelectorAll('ul')].filter(ul => !ul.parentNode.closest('ul'))
  top_lvl_uls.forEach(ul => {
    handleUl(ul)
  })

  html = doc.innerHTML
  html = html.replace(/<ul(?:\s+[^>]*)?>|<ol(?:\s+[^>]*)?>/ig, '{\\pard\\par}\n')
  html = html.replace(/<\/ul>|<\/ol>/ig, '{\\pard\\par}\n')
  html = html.replace(/([^\\\n]+)/g, $utils.str.decodeHTMLEntities)
  return html
}

function headingTagsToRtf(txt) {
  const heading_styles = {
    H1: '\\fs48\\b ',
    H2: '\\fs40\\b ',
    H3: '\\fs32\\b ',
    H4: '\\fs28\\b ',
    H5: '\\fs24\\b ',
    H6: '\\fs20\\b '
  } 
  txt = txt.replace(/<h([1-6])[^>]*>(.*?)<\/h\1>/gi, (_, level, content) => {
    const tag = `H${level}`
    return `{\\pard\\b\n${heading_styles[tag]}${content}\n\\par}`
  })

  return txt
}

export function toRTF(html) {
  if (!(typeof html === 'string' && html)) {
    return null
  }
  let tmpRichText
  let hasHyperlinks
  let richText = html

  // Delete HTML comments
  richText = richText.replace(/<!--[\s\S]*?-->/ig,'')
  richText = bulletListsToRtf(html)


  // Singleton tags
  richText = richText.replace(/<(?:hr)(?:\s+[^>]*)?\s*[/]?>/ig, '{\\pard \\brdrb \\brdrs \\brdrw10 \\brsp20 \\par}\n{\\pard\\par}\n')
  richText = richText.replace(/<(?:br)(?:\s+[^>]*)?\s*[/]?>/ig, '{\\pard\\par}\n')

  // Empty tags
  richText = richText.replace(/<(?:p|div|section|article)(?:\s+[^>]*)?\s*[/]>/ig, '')
  richText = richText.replace(/<(?:[^>]+)\/>/g, '')

  // Start tags
  richText = richText.replace(/<(?:b|strong)(?:\s+[^>]*)?>/ig, '{\\b\n')
  richText = richText.replace(/<(?:i|em)(?:\s+[^>]*)?>/ig, '{\\i\n')
  richText = richText.replace(/<(?:u|ins)(?:\s+[^>]*)?>/ig, '{\\ul\n')
  richText = richText.replace(/<(?:strike|del)(?:\s+[^>]*)?>/ig, '{\\strike\n')
  richText = richText.replace(/<sup(?:\s+[^>]*)?>/ig, '{\\super\n')
  richText = richText.replace(/<sub(?:\s+[^>]*)?>/ig, '{\\sub\n')
  richText = richText.replace(/<(?:p|div|section|article)(?:\s+[^>]*)?>/ig, '{\\pard\n')
  richText = headingTagsToRtf(richText)

  // End tags
  richText = richText.replace(/<\/(?:p|div|section|article)(?:\s+[^>]*)?>/ig, '\n\\par}\n')
  richText = richText.replace(/<\/(?:b|strong|i|em|u|ins|strike|del|sup|sub)(?:\s+[^>]*)?>/ig, '\n}')

  // // Hyperlinks
  richText = richText.replace(/<a(?:\s+[^>]*)?(?:\s+href=(["'])(?:javascript:void\(0?\);?|#|return false;?|void\(0?\);?|)\1)(?:\s+[^>]*)?>/ig, '{{{\n')
  tmpRichText = richText
  richText = richText.replace(/<a[^>]+href=(["'])(.*?)\1[^>]*>/ig, '{\\field{\\*\\fldinst{HYPERLINK\n "$2"\n}}{\\fldrslt{\\ul\\cf1\n')
  hasHyperlinks = richText !== tmpRichText
  richText = richText.replace(/<a(?:\s+[^>]*)?>/ig, '{{{\n')
  richText = richText.replace(/<\/a(?:\s+[^>]*)?>/ig, '\n}}}')


  // Tables
  richText = richText.replace(/<table(?:\s+[^>]*)?>/ig, '')
  richText = richText.replace(/<\/table>/ig, '\\pard\\par')
  richText = richText.replace(/<tr[^>]*>[\s\S]*?<\/tr>/g, (tr) => {
    let new_tr = tr
    new_tr = new_tr.replace(/<tr(?:\s+[^>]*)?>/ig, '\\trowd\\trgaph100\n')
    new_tr = new_tr.replace(/<\/tr>/ig, '\\row\n')
    let inc = 0
    new_tr = new_tr.replace(/<td(?:\s+[^>]*)?>/ig, (td) => {
      return td.replace(/<td(?:\s+[^>]*)?>/ig, `\\clbrdrt\\brdrs\\clbrdrl\\brdrs\\clbrdrb\\brdrs\\clbrdrr\\brdrs\\cellx${++inc * 3000} `)
    })
    new_tr = new_tr.replace(/<\/td>/ig, '\\intbl\\cell ')
    return new_tr
  })

  // Strip any other remaining HTML tags [but leave their contents]
  richText = richText.replace(/<(?:[^>]+)>/g, '')
  // Remove empty line at the beginning of the text

  richText = deleteEmptyLines(richText)
  // Prefix and suffix the rich text with the necessary syntax
  richText =
      '{\\rtf1\\ansi\n' + (hasHyperlinks ? '{\\colortbl\n;\n\\red0\\green0\\blue255;\n}\n' : '') + richText + '\n}'

  return richText.replace(/[\u0080-\uFFFF]/g, (char) => {
    return `\\u${char.charCodeAt(0)}?`
  })
}