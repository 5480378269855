export class FileType {
  constructor(type, max_size, exts) {
    this.type = type
    this.max_size = max_size
    this.exts = exts
  }

  get accept() {
    return this.exts.join(',')
  }
}

export const DOCUMENT = new FileType(
  'document',
  100 * 1024 * 1024,
  ['.txt', '.docx', '.pdf']
)

export const SAMPLE_CONTENT = new FileType(
  'sample-content',
  10 * 1024
)

export const AVATAR = new FileType(
  'avatar',
  5 * 1024 * 1024,
  ['.jpg', '.jpeg', '.png', '.gif']
)

export const LINKEDIN_CONTACTS = new FileType(
  'linkedin-contacts',
  5 * 1024 * 1024,
  ['.csv']
)

export const FTYPES = {
  'document': DOCUMENT,
  'sample-content': SAMPLE_CONTENT,
  'linkedin-contacts': LINKEDIN_CONTACTS,
  'avatar': AVATAR,
}
