import {nextTick} from 'vue'
import BaseStore from './base-store'
import StoreProxy from './store_proxy'
import {API_CHATS} from 'consts/api'
import * as errors from 'utils/errors'
import {CARDS_MOCKS} from 'pages/chats/cards-mocks'


import * as $utils from 'utils';
export class Chat extends BaseStore {
  constructor(values = {}) {
    const default_state = {
      id: undefined,
      history: [],
      title: '',
      prompt: '',
      generating: false,
      new_messages: [],
      ...values
    }
    super(default_state, {do_not_track: ['generating', 'history']})
    this._default_state = default_state
  }

  get title() {
    return this._state.title || this._state.user_query.title || this._state.user_query.text
  }

  setTitle(text) {
    this._state.title = text
  }

  _pushHistory(entry) {
    const history = this._state.history

    // remove intermediate message if any
    const last = history.at(-1)
    if (last?.intermediate) {
      this._state.history.pop()
    }

    // save new message
    this._state.history.push(entry)
    this._state.new_messages.push(entry)
  }

  _pushIntermediate(obj = {type: 'assistant'}) {
    const length = this._state.history.length
    if (length && this._state.history[length - 1].intermediate) {
      this._state.history[length - 1].data = obj.data
      return
    }

    this._state.history.push({
      intermediate: true,
      ...obj
    })
  }

  _pushCancelled() {
  }

  async doSave(rest, changed) {
    if (changed.has('new_messages')) {
      await rest.put(`${API_CHATS}/${this._state.id}/append`, {'history': this._state.new_messages})
      this._state.new_messages = []
      changed.delete('new_messages')
    }

    if (changed.size) {
      const to_write = {}
      for (const key of changed) to_write[key] = this._state[key]
      await rest.patch(`${API_CHATS}/${this._state.id}`, to_write)
    }
  }

  async doLoad(rest, params) {
    const chat = await rest.get(`${API_CHATS}/${this._state.id}`, params)

    CARDS_MOCKS
    // TODO: delete after api is ready
    // if (chat.history?.length > 0) {
    //   const last_history = chat.history.at(-1)
    //   last_history.data.cards = CARDS_MOCKS
    // }

    Object.assign(this._state, {...this._default_state, ...chat})

    // if there are no messages in chat at all
    // we start generating an answer
    if (this._state.history.length === 0) {
      nextTick(async () => {
        await this.doChat(this._state.user_query)
      })
      return
    }
  }

  async doChat(query) {
    this._state.generating = true
    this._pushHistory({type: 'user', data: query})

    try {
      this._pushIntermediate()

      const {id} = await this._rest.post(`${API_CHATS}/${this._state.id}/chat`, {user_query: query})
      const result = await this._tasks.wait(id, 1000, task => {
        if (task.result) this._pushIntermediate(task.result)
      })

      result.forEach(entry => this._pushHistory(entry))
    }
    catch (err) {
      this._pushHistory(Chat._map_chat_err(err))
    }
    finally {
      nextTick(async () => {
        this._state.generating = false
        await this.save()
      })
    }
  }

  stopChat() {
    this._tasks.cancelAll()
  }

  async updateHistory(...msgs) {
    msgs.forEach(x => this._pushHistory(x))
    await nextTick()
    await this.save()
  }

  abortGenerating() {
    if (this._state.generating) {
      this._rest.abortAll()
      this._tasks.cancelAll()
    }
  }

  async like(idx, feedback) {
    const liked = this._state.history[idx].liked == 1 ? 0 : 1
    await this._rest.patch(`${API_CHATS}/${this._state.id}/like`, {index: idx, liked, feedback})
    this._state.history[idx].liked = liked
  }

  async dislike(idx, feedback) {
    const liked = this._state.history[idx].liked == -1 ? 0 : -1
    await this._rest.patch(`${API_CHATS}/${this._state.id}/like`, {index: idx, liked, feedback})
    this._state.history[idx].liked = liked
  }

  async savePost(index, text) {
    await this._rest.patch(`${API_CHATS}/${this._state.id}/edit`, {index, text})
    this._state.history[index].data.text = text
  }

  async regenratePostFragment(history_idx, start_idx, end_idx) {
    try {
      const {id} = await this._rest.patch(`${API_CHATS}/${this._state.id}/regenerate_fragment`, {history_idx, start_idx, end_idx})
      const result = await this._tasks.wait(id)
      const text = this._state.history[history_idx].data.text

      const upd_text = text.slice(0, start_idx) + result + text.slice(end_idx)
      
      await this.savePost(history_idx, upd_text)
    }
    catch (err) {
      $utils.toasts.error('Failed to regenerate post section. Please try again later.')
    }
  }

  static _map_chat_err(err) {
    const DEFAULT_MESSAGE = 'There was an error while generating an answer. Please try again a bit later.'

    if (err instanceof errors.Cancelled) {
      return {
        data: {text: 'Answer generation cancelled by user'},
        type: 'user-cancelled'
      }
    }

    if (err instanceof errors.TaskError) {
      switch (err.task_error.code) {
        case 2:
          return {
            data: {
              text: 'Failed to scrape the source URL. Please check the URL and try again.',
              task_id: err.task_id
            },
            type: 'assistant-error'
          }
        case 1000:
          return {
            data: {
              text: 'Not enough info to generate a meaningful response.',
              task_id: err.task_id
            },
            type: 'assistant-error'
          }
        case 1001:
          return {
            data: {
              text: 'Sorry, I can\'t help with that.',
              task_id: err.task_id
            },
            type: 'assistant-error'
          }
        default:
          return {
            data: {
              text: DEFAULT_MESSAGE,
              task_id: err.task_id
            },
            type: 'error'
          }
      }
    }

    return {
      data: {
        text: DEFAULT_MESSAGE,
        // convert Error to plain dict
        // use json_short, we do not want to keep long stacks in chat history
        error: JSON.parse(errors.json_short(err))
      },
      type: 'error'
    }
  }
}

class Сhats extends BaseStore {
  constructor(chat_type) {
    super({
      chats: []
    })
    this.chat_type = chat_type
  }

  async doLoad(rest, params) {
    const chats = await rest.get(API_CHATS, {type: this.chat_type, ...params})
    this._state.chats = []
    for (const chat of chats) {
      this._state.chats.push(new Chat(chat))
    }
  }

  getChat(id) {
    return this._state.chats.find(x => x.id === id)
  }

  async removeChat(id) {
    await this._rest.delete(`${API_CHATS}/${id}`)
    this._state.chats = this._state.chats.filter(x => x.id !== id)
  }

  async newChat(user_query, type) {
    const chat_data = await this._rest.post(API_CHATS, {user_query, type})
    const chat = new Chat(chat_data)

    this._state.chats.unshift(chat)
    while (this._state.chats.length > 20) {
      const to_remove = this._state.chats.pop()
      await this._rest.delete(`${API_CHATS}/${to_remove.id}`)
    }

    return chat
  }
}

const chats = new StoreProxy(new Сhats('chat'), {array_prop: 'chats'})
export default chats

export const onboarding = new StoreProxy(new Сhats('onboarding'), {array_prop: 'chats'})
