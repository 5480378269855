export const DEFAULT = BACKEND_URL
export const DEFAULT_API = `${DEFAULT}/api`
export const API_LOGIN = `${DEFAULT_API}/login`
export const API_PROFILE = `${DEFAULT_API}/users/me`
export const API_ADMIN_USERLIST = `${DEFAULT_API}/users`
export const API_ADMIN_USER_UPDATE = `${DEFAULT_API}/users`
export const API_COMPOSE = `${DEFAULT_API}/tasks/compose`
export const API_TASKS = `${DEFAULT_API}/tasks`
export const API_TEXT = `${DEFAULT_API}/tasks/text`
export const API_UPLOAD_DOCUMENT = `${DEFAULT_API}/tasks/upload/document`
export const API_SETTINGS = `${DEFAULT_API}/settings`
export const API_DOCUMENT_STATUS = `${DEFAULT_API}/tasks/check/status`
export const API_AUTHORIZATION_URL = `${DEFAULT_API}/authorize`
export const API_AUDIT_CLIENT = `${DEFAULT_API}/audit/v1/client`
export const API_AUDIT_LOGS = `${DEFAULT_API}/audit/v1/events`
export const API_AUDIT_GROUPS = `${DEFAULT_API}/audit/v1/groups`
export const API_AUDIT_USERS = `${DEFAULT_API}/audit/v1/users`
export const API_FILES = `${DEFAULT_API}/files`
export const API_CHATS = `${DEFAULT_API}/chats`
export const API_INTEGRATIONS = `${DEFAULT_API}/integrations`
export const API_CONTACTS = `${DEFAULT_API}/contacts`
export const API_COMPANIES = `${DEFAULT_API}/companies`
export const API_FEED = `${DEFAULT_API}/feed`
export const TASK_AMDOCS_SCRAPE = `${API_TASKS}/scrape/amdocs`
export const TASK_AMDOCS_SCRAPE_DELETE = `${API_TASKS}/scrape/amdocs/delete`
export const AUDIT_TOKEN = AUDIT_TOKEN_URL
export const API_REALM = `${DEFAULT_API}/realm`
export const API_REALMS = `${DEFAULT_API}/realms`
export const API_FEEDBACK = `${DEFAULT_API}/feedback`

export const ARTICLE_STATUS = {
  NOT_AVAILABLE: 0,
  AVAILABLE: 1,
  SHORT_TEXT: 2,
}
