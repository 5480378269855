import * as $utils from 'utils';
export function join(string_arr, params = {}) {
  const {delimeter = ', ', capitalize} = params
  let copy = [...string_arr]
  if (capitalize) {
    copy = copy.map($utils.str.capitalize)
  }
  return copy.join(delimeter)
}

export function joinUrls(string_arr, params = {}) {
  const {delimeter = ', ', protocol = 'https://'} = params
  return string_arr.filter(el => el.length > 0).map(el => {
    const href = protocol + el
    return `<a href="${href}">${href}</a>`
  }).join(delimeter)
}