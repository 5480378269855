import pdfMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import htmlToPdfmake from 'html-to-pdfmake'

pdfMake.vfs = pdfFonts.pdfMake.vfs


function deleteEmpty(node) {
  if (typeof node.text === 'string') {
    if (!node.nodeName) node.nodeName = 'SPAN'

    //TODO: need to fix this hardcode. need to remove empties only from block elements
    if (node.nodeName !== 'A') node.text = node.text.trim()
    if (node.text === ':') node.text = ''
  }
  const stack = node.stack || node.text || node.ul
  if (Array.isArray(stack)) stack.forEach(node => deleteEmpty(node))
}

export async function saveDOM(html, {name = 'pdf-document', options, delete_empty = false} = {}) {
  const val = htmlToPdfmake(html, options)

  let imgidx = 0
  const images = {}
  
  const add_images = (node) => {
    if (node.nodeName === 'IMG') {
      const imgid = `image-${++imgidx}`
      
      images[imgid] = {'url': node.image}
      node.image = imgid

      for (const key in node) {
        if (key.toLowerCase().indexOf('width') !== -1) node['width'] = node[key]
        // setting height would cause image to be deformed if in original image width != height
        // if (key.toLowerCase().indexOf('height') !== -1) node['height'] = node[key]  
      }
    }

    if (node.stack) {
      node.stack.forEach(node => add_images(node))
    }
  }
  val.forEach(node => add_images(node))

  if (delete_empty) val.forEach(node => deleteEmpty(node))

  const dd = {
    content:val,
    images: images,
    defaultStyle: {
      font: 'Roboto'
    }
  }
  pdfMake.createPdf(dd).download(`${name}.pdf`)
}
