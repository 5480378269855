import {nextTick, reactive} from 'vue'
import {errors, Rest, settings as $settings} from 'utils'
import router from 'router'
import {signOut} from '@aws-amplify/auth'
import $chats from 'stores/chats'
import $profile from 'stores/profile'
import $integrations from 'stores/integrations'
import {SETTINGS_ONBOARDING, SETTINGS_FILE_UPLOAD_ENABLED} from 'consts/settings'


// TODO: Change to BaseStore
function defaultState() {
  const state = {
    loading: false,
    error: undefined,
    debug: true,
    nav_pinned: localStorage.getItem('nav_pinned') === 'true',
    onboarding: undefined,
    file_upload_enabled: true,
  }
  return state
}

function makeState() {
  const state = reactive({
    ...defaultState()
  })
  return state
}

export const store = {
  state: makeState(),
  _rest: new Rest(),
  
  goToLogin() {
    return router.push({
      name: 'login'
    })
  },

  goToWelcome() {
    return router.push({
      name: 'welcome'
    })
  },

  goToBrand() {
    return router.push({
      name: 'brand'
    })
  },

  goToProfile() {
    return router.push({
      name: 'profile'
    })
  },

  goToChats() {
    return router.push({
      name: 'new-chat'
    })
  },

  goToChat(id) {
    return router.push({
      name: 'chat',
      query: {id}
    })
  },

  goToSettings(tab, params = {}) {
    return router.push({
      name: 'settings',
      query: {tab},
      ...params
    })
  },

  goToOnboarding() {
    return router.push({
      name: 'onboarding'
    })
  },

  get nav_pinned() {
    return this.state.nav_pinned
  },

  set nav_pinned(value) {
    localStorage.setItem('nav_pinned', value)
    this.state.nav_pinned = value
  },

  async setFileUploadEnabled(data) {
    await $settings.put(SETTINGS_FILE_UPLOAD_ENABLED, data)
    this.state.file_upload_enabled = data
  },

  async setOnboarding(data) {
    await $settings.put(SETTINGS_ONBOARDING, data)
    this.state.onboarding = data
  },

  // TODO: for tests
  // remove after testing
  async deleteOnboarding() {
    await $settings.remove(SETTINGS_ONBOARDING)
    this.state.onboarding = undefined
    this.goToOnboarding()
  },

  setError(error) {
    if (error instanceof errors.Cancelled) {
      return console.warn(error.message)
    }

    if (error instanceof errors.Unauthorized) {
      console.log(error)
      nextTick(async () => await signOut())
    }

    this.state.error = error
    console.error(error)
  },

  clearError() {
    this.state.error = undefined

    // We handle login in a special way - do not allo to reload the same url 
    // It can be SAML error redirect and if we just reload user would appear on
    // the same error page. So we force to start clean login
    if (window.location.pathname == '/login') {
      window.location.href = window.location.origin
      nextTick(() => {
        window.location.reload()
      })
      return
    }

    window.location.reload()
  },

  async start() {
    this.state.loading = true
    await $profile.load()
    await $chats.load()
    await $integrations.load()
    $integrations.checkReconnect()
    this.state.onboarding = await $settings.get(SETTINGS_ONBOARDING)
    this.state.file_upload_enabled = await $settings.get(SETTINGS_FILE_UPLOAD_ENABLED)
    this.state.loading = false
  },

  //
  // TODO: Change getters to BaseStore?
  //
  get loading() {
    return this.state.loading
  },

  get onboarding() {
    return this.state.onboarding
  },

  get fileUploadEnabled() {
    return this.state.file_upload_enabled
  },

  get error() {
    return this.state.error
  },

  get parent_page() {
    return window.opener
  }
}

export default store
