export const MAX_DIG = 57

// default decimal unicode values
const A_UP = 65
const A_LOW = 97
export const ZERO = 48

// Math sans ES6 unicode values
const DEF_UP = 120224 // '\u{1D5A0}'.codePointAt()
const DEF_LOW = 120250 // '\u{1D5BA}'.codePointAt() 
export const DEF_ZERO = 120802 // '\u{1D7E2}'.codePointAt() 

const BOLD_UP = 120276 // '\u{1D5D4}'.codePointAt() 
const BOLD_LOW = 120302 // '\u{1D5EE}'.codePointAt() 
export const BOLD_ZERO = 120812 // '\u{1D7EC}'.codePointAt() 

const ITALIC_UP = 120328 // '\u{1D608}'.codePointAt() 
const ITALIC_LOW = 120354 // '\u{1D622}'.codePointAt() 

const BOLD_ITALIC_UP = 120380 // '\u{1D63C}'.codePointAt() 
const BOLD_ITALIC_LOW = 120406 // '\u{1D656}'.codePointAt() 

// diffs between math sans and default
export const DEF_DIF_UP = DEF_UP - A_UP
export const DEF_DIF_LOW = DEF_LOW - A_LOW

export const BOLD_DIF_UP = BOLD_UP - A_UP
export const BOLD_DIF_LOW = BOLD_LOW - A_LOW

export const ITALIC_DIF_UP = ITALIC_UP - A_UP
export const ITALIC_DIF_LOW = ITALIC_LOW - A_LOW

export const BOLD_ITALIC_DIF_UP = BOLD_ITALIC_UP - A_UP
export const BOLD_ITALIC_DIF_LOW = BOLD_ITALIC_LOW - A_LOW
