export class Cancelled extends Error {
}

export class TaskError extends Error {
  constructor(task_id, task_error) {
    super('Task execution failed')
    this.task_id = task_id
    this.task_error = task_error
  }
}

export class CustomError extends Error {
  constructor(url, http_code, message) {
    super(`HTTP ${http_code} - ${message} at ${url}`)
    this.url = url
    this.http_code = http_code
  }
}

export class RestError extends Error {
  constructor(url, http_code, message, resp) {
    super(`HTTP ${http_code} - ${message} at ${url}`)
    this.url = url
    this.http_code = http_code
    this.response = resp
  }
}

export class Unauthorized extends RestError {
  constructor(url, message, resp) {
    super(url, 401, message, resp)
  }
}

export class NotFound extends RestError {
  constructor(url, message, resp) {
    super(url, 404, message, resp)
  }
}

export class RestParseError extends RestError {
  constructor(url, http_code, message, resp) {
    super(url, http_code, message, resp)
  }
}

export function json_short(obj) {
  if (obj instanceof Error) {
    obj = Object.assign(
      // message & stack are non-enumerable and JSON.stringify ignores them
      // convert message to enumerable, ignore message for a short json
      {'message': obj.message},
      // it might be an object derived from Error
      // let's include its possible properties
      obj
    )
  }
  return JSON.stringify(obj, undefined, 2)
}

export function json_long(obj) {
  if (obj instanceof Error) {
    obj = Object.assign(
      // message & stack are non-enumerable and JSON.stringify ignores them
      // convert stack to enumerable, ignore message (included in stack)
      {'stack': obj.stack},
      // it might be an object derived from Error
      // let's include its possible properties
      obj
    )
  }
  return JSON.stringify(obj, undefined, 2)
}