import BaseStore from './base-store'
import {get_file} from 'stores/files'
import {API_PROFILE, API_ADMIN_USERLIST, API_ADMIN_USER_UPDATE} from 'consts/api'
import router from 'router'

class ProfileStore extends BaseStore {
  constructor() {
    super({
      id: undefined,
      name: '',
      email: '',
      is_admin: false,
      is_superuser: false,

      // old profile
      web_notification: true,
      mail_notification: true,
      web_notification_id: undefined,
      occupied_storage: 0,
      storage_limit: 0,

      // new profile
      first_name: undefined,
      last_name: undefined,
      title: undefined,
      company_name: undefined,
      company_site: undefined,
      avatar_id: undefined,
      avatar_ur: undefined,
      bio: undefined,
      current_tab: 'ADMIN' // # TODO: REMOVE
    }, {
      schema_version: 2
    })
  }

  get display_name() {
    if (this.first_name || this.last_name) {
      return `${this._state.first_name || ''} ${this._state.last_name || ''}`.trim()
    }
    return this.name
  }

  async doLoad(rest, params) {
    const data = await rest.get(API_PROFILE, params)

    // Do we need this check?
    for (const prop in data) {
      if (data[prop] === null || data[prop] === undefined) {
        delete data[prop]
      }
    }
    Object.assign(this._state, data)

    if (this._state.avatar_id) {
      const avatar_data = await get_file(this._state.avatar_id)
      this._state.avatar_url = avatar_data.url
    }
  }

  async doSave(rest, changed) {
    const to_write = {}
    for (const key of changed) to_write[key] = this._state[key]
    await rest.patch(API_PROFILE, to_write)
  }

  async fetchUserTable() {
    return await this._rest.get(API_ADMIN_USERLIST)
  }

  async updateUser(id, what) {
    await this._rest.patch(`${API_ADMIN_USER_UPDATE}/${id}`, what)
  }

  goToProfileTab(tab) {
    this._state.current_tab = tab
    router.push({
      name: 'profile'
    })
  }
}

const profile = new ProfileStore()
export default profile