import $ from 'jquery';
export function error(message) {
  $.toast({
    displayTime: 5000,
    class: 'error',
    position: 'bottom right',
    closeIcon: 'right',
    message
  })
}

export function message(data) {
  $.toast({
    displayTime: 5000,
    position: 'bottom right',
    closeIcon: 'right',
    ...data
  })
}