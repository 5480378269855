
const __default__ = {
  props: {
    size: {type: String, default: 'massive'},
    inline: {type: Boolean, default: false},
    mini: {type: Boolean, default: false},
    tiny: {type: Boolean, default: false},
    small: {type: Boolean, default: false},
    medium: {type: Boolean, default: false},
    large: {type: Boolean, default: false},
    big: {type: Boolean, default: false},
    huge: {type: Boolean, default: false},
    color: {type: String, default: 'var(--brand-500)'}
  },
  computed: {
    c_size() {
      if (this.mini) return '1.6rem'
      if (this.tiny) return '2.2rem'
      if (this.small) return '2.8rem'
      if (this.medium) return '3.2rem'
      if (this.large) return '4.8rem'
      if (this.big) return '6.2rem'
      if (this.huge) return '7.8rem'
      return '8.2rem' // massive
    }
  }
}

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "3f299cc3-c_size": (_ctx.c_size),
  "3f299cc3-color": (_ctx.color)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__